import { DependencyList, useEffect } from "react";
import ReactGA from "react-ga4";

import { config } from "../../../config";
import { AnalyticsEffectCallback } from "./google-analytics.types";

export const useAnalyticsInit = () => {
  const { gaMeasurementId } = config;

  useEffect(() => {
    if (!gaMeasurementId || ReactGA.isInitialized) return;
    ReactGA.initialize(gaMeasurementId, {
      gaOptions: {
        // See: https://developers.google.com/analytics/devguides/collection/analyticsjs/field-reference#create
      }
    });
  }, [gaMeasurementId]);
};

export const useAnalyticsEffect = (
  effect?: AnalyticsEffectCallback,
  deps: DependencyList = []
) => {
  const { gaMeasurementId } = config;

  if (!ReactGA.isInitialized && gaMeasurementId) {
    ReactGA.initialize(gaMeasurementId, {
      gaOptions: {
        // See: https://developers.google.com/analytics/devguides/collection/analyticsjs/field-reference#create
      }
    });
  }

  useEffect(() => {
    if (!gaMeasurementId) return;
    effect && effect(ReactGA);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gaMeasurementId, ...deps]);

  return ReactGA;
};
