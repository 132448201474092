export type Route = {
  path: string;
  title: string;
  fullPath: string;
  subRoutes: Record<string, Route>;
};

export type RouteTitle = { fullPath: string; title: string };

export type RouteKey =
  | "profile"
  | "appointments"
  | "family"
  | "dashboard"
  | "bha"
  | "setup"
  | "booking";

export const DEFAULT_PAGE_TITLE = "Best Health";
