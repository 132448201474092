import { config } from "config";
import { Environment } from "core/domain";
import { action, computed, makeObservable, observable } from "mobx";

import { useAccount } from "@azure/msal-react";

export class UserPreferencesStore {
  // Features are visible by default in the dev environment
  private _areToggledFeaturesVisible = config.environment === Environment.Dev;
  private _isProductionEnvironment = config.environment === Environment.Prod;

  public constructor() {
    makeObservable<
      UserPreferencesStore,
      "_areToggledFeaturesVisible" | "_isProductionEnvironment"
    >(this, {
      _isProductionEnvironment: observable,
      _areToggledFeaturesVisible: observable,
      toggleFeatureToggle: action,
      areToggledFeaturesVisible: computed
    });
  }

  public toggleFeatureToggle = () => {
    // For production the features aren't able to be toggled
    if (!this._isProductionEnvironment) {
      this._areToggledFeaturesVisible = !this._areToggledFeaturesVisible;
    }
  };

  public get areToggledFeaturesVisible(): boolean {
    if (!this._isProductionEnvironment) return this._areToggledFeaturesVisible;

    // Ensure that toggled features aren't displayed in production
    return false;
  }

  public get isProductionEnvironment(): boolean {
    return this._isProductionEnvironment;
  }

  isUserLoggedIn = () => {
    const account = useAccount();
    return !!account;
  };
}
