import { Route, RouteTitle } from "./routes.types";

/**
 * Returns RouteTitle object from Route
 * @param route
 */
const getRouteTitle = (route: Route): RouteTitle => {
  return { fullPath: route.fullPath, title: route.title };
};

/**
 * Returns routes nesting number
 * @param route
 */
const getRouteNesting = (route: RouteTitle) => {
  return route.fullPath
    .split("/")
    .reduce((acc: string[], item: string) => [...acc, ...item.split("-")], [])
    .filter(i => i.length > 0).length;
};

/**
 * Recursively return Array<RouteTitle> from Array<Route>
 * @param routes
 */
export const getRoutesTitles = (routes: Route[]): RouteTitle[] => {
  return routes
    .reduce((acc: RouteTitle[], item: Route) => {
      const hasSubRoutes = !!Object.values(item.subRoutes).length;
      if (hasSubRoutes) {
        return [
          ...acc,
          ...getRoutesTitles(Object.values(item.subRoutes)),
          getRouteTitle(item)
        ];
      }
      return [...acc, getRouteTitle(item)];
    }, [] as RouteTitle[])
    .sort((a, b) => {
      // sort routes by route nesting
      const aNesting = getRouteNesting(a);
      const bNesting = getRouteNesting(b);
      return bNesting - aNesting;
    });
};
