import { config } from "config";
import { PropsWithChildren } from "react";
import { QueryClient, QueryClientProvider } from "react-query";

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
      staleTime: config.query.staleTimeMillis
    }
  }
});

const ReactQueryProvider = ({ children }: PropsWithChildren<unknown>) => {
  return (
    <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
  );
};

// eslint-disable-next-line import/no-default-export
export default ReactQueryProvider;
