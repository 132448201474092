import { BookingStep } from "core/domain/booking/Enums";
import { guid } from "core/types";
import qs, { StringifiableRecord } from "query-string";
import urlJoin from "url-join";

import { routes } from "../../core/routes/routes";

export const BHB_ROOT_ROUTE = "/booking";

export const RouteMap = {
  cancelBooking: "cancellation/",
  policy: "policy/",
  rescheduleBooking: "reschedule/",

  queries: {
    appointmentId: "appointmentId",
    locationId: "locationId"
  },

  bookingSteps: {
    [BookingStep.Unknown]: routes.booking.subRoutes.error.path,
    [BookingStep.SelectPatientType]: routes.booking.subRoutes.patientType.path,
    [BookingStep.SelectAppointmentType]:
      routes.booking.subRoutes.appointmentType.path,
    [BookingStep.AppointmentTypeInformation]:
      routes.booking.subRoutes.appointmentTypeInformation.path,
    [BookingStep.SelectAppointmentSlot]:
      routes.booking.subRoutes.appointmentSlot.path,
    [BookingStep.CompleteProfile]:
      routes.booking.subRoutes.completeProfile.path,
    [BookingStep.SelectPatient]: routes.booking.subRoutes.patient.path,
    [BookingStep.AddDependant]: routes.booking.subRoutes.addDependant.path,
    [BookingStep.AddPatientNotes]: routes.booking.subRoutes.notes.path,
    [BookingStep.ConfirmBooking]: routes.booking.subRoutes.confirm.path,
    [BookingStep.BookingSuccess]: routes.booking.subRoutes.complete.path,
    [BookingStep.NotAcceptingBookings]:
      routes.booking.subRoutes.unavailable.path,
    [BookingStep.NotAcceptingNewPatients]:
      routes.booking.subRoutes.unaccepted.path,
    [BookingStep.PracticeOutage]: routes.booking.subRoutes.practiceOutage.path
  },

  getBookingPath: (
    path: string,
    query: StringifiableRecord,
    absolute: boolean = false
  ) => {
    const url = absolute ? urlJoin(BHB_ROOT_ROUTE, path) : path;
    return qs.stringifyUrl({
      url,
      query
    });
  },

  getBookingStepPath: (
    stepOrRoute: BookingStep | string,
    practiceId: guid,
    absolute: boolean = false
  ) => {
    const route =
      typeof stepOrRoute === "string"
        ? stepOrRoute
        : RouteMap.bookingSteps[stepOrRoute];

    const url = absolute ? urlJoin(BHB_ROOT_ROUTE, route) : route;

    return qs.stringifyUrl({
      url,
      query: { locationId: practiceId }
    });
  },

  getBookingWizardAbsoluteUrl: () => {
    const { locationId } = qs.parse(window.location.search);

    return qs.stringifyUrl({
      url: urlJoin(
        window.location.origin,
        window.location.pathname,
        BHB_ROOT_ROUTE
      ),
      query: { locationId }
    });
  }
};
