import type { ProviderTimeSlot } from "core/domain/booking";
import { DateTime } from "luxon";

/**
 * All the times that are available for a single day
 */
export interface DailyAvailableTimes {
  day: DateTime;
  startTimes: DateTime[];
  pageIndex: number;
}

export type ProviderTimeSlotEventHandler = (
  providerTimeSlot: ProviderTimeSlot
) => void | Promise<void>;

export const ProviderOnlineStatusCodes = {
  yes: "YES",
  no: "NO",
  call: "CALL"
};
