import { Literal } from "components/Literal";

import Alert from "@mui/material/Alert";

interface Props {
  title?: string;
  error?: Error | unknown;
}

const hasMessage = (error?: unknown): error is { message: string } => {
  if (!error) return false;
  if ((error as { message: unknown }) === undefined) return false;

  const errorWithMessage = error as { message: unknown };
  return typeof errorWithMessage.message === "string";
};

export const ErrorBox = ({ title, error }: Props) => {
  const errorMessage = hasMessage(error)
    ? error.message
    : (error as object)?.toString() ?? null;

  const text = title ?? errorMessage ?? "An error has occurred.";
  const details = (title && errorMessage) ?? null;

  return (
    <Alert severity="error">
      <Literal variant="title2">{text}</Literal>
      {details && <Literal>{details}</Literal>}
    </Alert>
  );
};
