const compare = (left?: string, right?: string, caseSensitive = false) => {
  // Purposely using type-coercion here to see if the values are nullish
  const hasLeft = left != null;
  const hasRight = right != null;

  if (hasLeft && hasRight) {
    const options: Intl.CollatorOptions = {
      sensitivity: caseSensitive ? "variant" : "accent"
    };

    return left.localeCompare(right, [], options);
  }

  if (hasLeft) return 1;
  if (hasRight) return -1;
  return 0;
};

const areEqual = (left?: string, right?: string, caseSensitive = false) =>
  compare(left, right, caseSensitive) === 0;

export const strings = {
  compare,
  areEqual
};

export const capitalizeFirstLetter = (value: string) => {
  return value.charAt(0).toUpperCase() + value.toLowerCase().slice(1);
};
