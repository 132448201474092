import { useEffect } from "react";
import { useLocation } from "react-router-dom";

import { routes } from "../routes/routes";
import { DEFAULT_PAGE_TITLE } from "../routes/routes.types";
import { getRoutesTitles } from "../routes/routes.utils";

const routesTitles = getRoutesTitles(Object.values(routes));
export const usePageTitle = (overridePageTitle?: string) => {
  const location = useLocation();
  useEffect(() => {
    const match = routesTitles.find(rt =>
      location.pathname.includes(rt.fullPath)
    );
    document.title = match?.title ?? overridePageTitle ?? DEFAULT_PAGE_TITLE;
  }, [overridePageTitle, location]);
};
