import Hotjar from "@hotjar/browser";

import { config } from "../../../config";

const HOTJAR_VERSION = 6;

export const initHotJar = () => {
  if (!window.appConfig.isLocal) {
    const siteId = Number(config.hotjarSiteId);
    Hotjar.init(siteId, HOTJAR_VERSION);
  }
};
