import { DateTime } from "luxon";
import { BaseSchema } from "yup";
import { date as defaults } from "yup/lib/locale";
import Reference from "yup/lib/Reference";

/**
 * Defines a schema that can be used for validating Luxon DateTime instances.
 *
 * @remarks
 * Unfortunately the default `DateSchema` from yup does not allow inheritance with types that aren't
 * a `Date` - so we have to effectively re-implement the `DateSchema`.
 *
 * See: https://github.com/jquense/yup/blob/master/src/date.ts
 */
class DateTimeSchema extends BaseSchema<DateTime> {
  static create() {
    return new DateTimeSchema();
  }

  constructor() {
    super();

    this.withMutation(() => {
      this.transform(function (value, originalValue) {
        if (this.isType(value)) return value;

        try {
          const dateTime = DateTime.fromObject(originalValue);
          if (dateTime.isValid) return dateTime;
        } catch (err) {}

        // *shrug* This doesn't look like anything we can convert to a DateTime
        return undefined;
      });
    });
  }

  private prepareParam(
    ref: DateTime | Reference<DateTime> | unknown,
    name: string
  ): DateTime | Reference<DateTime> {
    if (Reference.isRef(ref)) {
      return ref as Reference<DateTime>;
    }

    if (typeof ref === "function") {
      const exprValue = ref() as DateTime;
      if (this._typeCheck(exprValue)) {
        return exprValue;
      }
    }

    const castValue = this.cast(ref);
    if (this._typeCheck(castValue)) {
      return castValue;
    }

    throw new TypeError(
      `'${name}' must be a DateTime, or an expression which produces a DateTime (received ${ref} which is of type '${typeof ref}')`
    );
  }

  min(min: unknown | Reference<DateTime>, message = defaults.min) {
    const limit = this.prepareParam(min, "min");

    return this.test({
      message,
      name: "min",
      exclusive: true,
      params: { min },
      test(value) {
        return value == null || value >= this.resolve(limit);
      }
    });
  }

  max(max: unknown | Reference<DateTime>, message = defaults.max) {
    const limit = this.prepareParam(max, "max");

    return this.test({
      message,
      name: "max",
      exclusive: true,
      params: { max },
      test(value) {
        const resolvedMax = this.resolve(limit);
        return value == null || value <= resolvedMax;
      }
    });
  }

  // @ts-ignore
  protected override _typeCheck(value): value is DateTime {
    return value instanceof DateTime && (value as DateTime).isValid;
  }
}

export const yupExtensions = {
  dateTime: () => new DateTimeSchema()
};
