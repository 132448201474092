import { action, computed, makeObservable, observable } from "mobx";

const DEFAULT_NOTIFICATION_TIMEOUT_MILLIS = 6000;

export type NotificationType = "info" | "warning" | "success" | "error";

export interface Notification {
  id: number;
  type: NotificationType;
  title: string | null;
  message: string;
  count: number;
  expiresAtMillis: number | null;
}

type MessageOrError = string | Error | unknown | null | undefined;
export type NotificationFactory = (
  title: string,
  details?: MessageOrError,
  timeoutMillis?: number | null
) => number;

export class NotificationStore {
  private static _id = 0;
  private notifications: Notification[] = [];

  public constructor() {
    // We have to pass 'notifications' as an argument here because TypeScript.
    // See point 8 at: https://mobx.js.org/observable-state.html#limitations
    makeObservable<NotificationStore, "notifications">(this, {
      notifications: observable,
      notifyInfo: action,
      notifySuccess: action,
      notifyWarning: action,
      notifyError: action,
      dismiss: action,
      current: computed
    });
  }

  public notifyInfo: NotificationFactory = (
    titleOrMessage,
    details,
    timeoutMillis
  ) => this.add("info", titleOrMessage, { details, timeoutMillis });
  public notifySuccess: NotificationFactory = (
    titleOrMessage,
    details,
    timeoutMillis
  ) => this.add("success", titleOrMessage, { details, timeoutMillis });
  public notifyWarning: NotificationFactory = (
    titleOrMessage,
    details,
    timeoutMillis
  ) => this.add("warning", titleOrMessage, { details, timeoutMillis });
  public notifyError: NotificationFactory = (
    titleOrMessage,
    details,
    timeoutMillis
  ) => this.add("error", titleOrMessage, { details, timeoutMillis });

  private add = (
    type: NotificationType,

    titleOrMessage: string,
    options: {
      details: MessageOrError;
      timeoutMillis: number | null | undefined;
    }
  ): number => {
    const { details, timeoutMillis = null } = options;
    const expiresAtMillis =
      timeoutMillis !== Infinity
        ? new Date().valueOf() +
          (timeoutMillis || DEFAULT_NOTIFICATION_TIMEOUT_MILLIS)
        : null;

    const message = this.extractMessage(details);
    const existing = this.notifications.find(
      n =>
        n.type === type && n.title === titleOrMessage && n.message === message
    );

    if (existing) {
      existing.count++;
      existing.expiresAtMillis = expiresAtMillis;

      return existing.id;
    }

    // If we don't have both a title and a message, then we omit the title due to the way that notifications
    // get formatted.
    const hasTitleAndMessage = !!(titleOrMessage && message);

    const notification: Notification = {
      id: ++NotificationStore._id,
      type,
      title: hasTitleAndMessage ? titleOrMessage : null,
      message: message || titleOrMessage,
      count: 1,
      expiresAtMillis
    };

    this.notifications.push(notification);
    return notification.id;
  };

  private extractMessage = (details: MessageOrError): string | null => {
    if (details == null) return null;
    if (typeof details === "string") return details;
    if (details instanceof Error) return details.message;

    return (details as Object).toString() || null;
  };

  public dismiss = (notification: number | Notification) => {
    this.notifications = this.notifications.filter(
      n => n !== notification && n.id !== notification
    );
  };

  public dismissAll = action(() => {
    if (this.count > 0) {
      this.notifications = [];
    }
  });

  public get count() {
    return this.notifications.length;
  }

  public get current() {
    const now = new Date().valueOf();

    // Dismiss any notifications that have already expired. This is to cater for notifications that are raised while
    // the browser tab is not in focus. The `Snackbar` component (which is responsible for displaying notifications)
    // won't show these until the browser tab comes back in focus which means we could have a lot of notifications
    // that really aren't relevant anymore.
    for (const notification of this.notifications) {
      if (!notification.expiresAtMillis || notification.expiresAtMillis > now) {
        return notification;
      }

      this.dismiss(notification);
    }

    return null;
  }
}
