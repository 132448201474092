import { strings } from "core/strings";
import { guid } from "core/types/Guid";
import { yupExtensions } from "core/validation/yupExtensions";
import { DateTime } from "luxon";
import * as yup from "yup";

import { Address } from "./Address";
import { Country } from "./booking";

export interface Person {
  id: guid | null;
  firstName: string;
  lastName: string;
  dateOfBirth?: DateTime;
  mobile: string;
  address: Address;
}

export const PersonMeta = {
  getDisplayName: (person: Person) =>
    `${person.firstName || ""} ${person.lastName || ""}`.trim(),
  getInitials: (person: Person) =>
    `${person.firstName[0] || ""}${person.lastName[0] || ""}`.trim(),

  // The oldest person ever to have lived was 122
  getMinDateOfBirth: () => DateTime.now().minus({ years: 125 }).startOf("day"),

  // Users must be at least 14 years old.
  getMaxDateOfBirthForUserProfile: () =>
    DateTime.now().minus({ years: 14 }).startOf("day"),

  sort: (left: Person, right: Person) => {
    const lastNameResult = strings.compare(left.lastName, right.lastName);
    return lastNameResult === 0
      ? strings.compare(left.firstName, right.firstName)
      : lastNameResult;
  }
};

/**
 * Validation schema for the required fields for a person - firstName, lastName and dateOfBirth.
 */

export const PersonValidationSchema = yup.object().shape({
  firstName: yup
    .string()
    .required("Please enter a first name")
    .min(1, "The first name you have entered is too short"),
  lastName: yup
    .string()
    .required("Please enter a last name")
    .min(1, "The last name you have entered is too short")
});

export const DateOfBirthSchema = yupExtensions
  .dateTime()
  .nullable()
  .min(
    PersonMeta.getMinDateOfBirth(),
    () =>
      "The date of birth is before the minimum allowed value " +
      `(${PersonMeta.getMinDateOfBirth().toLocaleString(DateTime.DATE_SHORT)})`
  );

/**
 * Validation schema for an Australian or NZ mobile phone number
 */
export const MobileNumberValidationSchema = yup
  .string()
  .nullable()
  .required("Please enter a mobile number");

export const NZValidNumber = {
  name: "nz-valid-phone-number",
  message:
    "Your mobile must start with '02', '04' or '05' and not more than 11 digits long",
  test: (value, context) => {
    const { parent } = context;
    const country = parent?.address?.country;
    if ((country === Country.NewZealand || !country) && value) {
      return new RegExp(/^0[245]\d{5,9}$/).test(value);
    }

    return true;
  }
};

export const AUValidNumber = {
  name: "au-valid-phone-number",
  message:
    "Your mobile must start with '02', '04' or '05' and not more than 10 digits long",
  test: (value, context) => {
    const { parent } = context;
    const country = parent?.address?.country;

    if (value && country === Country.Australia) {
      return new RegExp(/^0[245]\d{8}$/).test(value);
    }
    return true;
  }
};
