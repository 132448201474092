import { ReactPlugin as AppInsightsReactPlugin } from "@microsoft/applicationinsights-react-js";
import {
  ApplicationInsights,
  IConfiguration
} from "@microsoft/applicationinsights-web";

import { config } from "../../../config";

export const initAppInsights = () => {
  const appInsightConfig: IConfiguration = {
    instrumentationKey: config.appInsightsInstrumentationKey
  };

  const appInsightPlugin = new AppInsightsReactPlugin();

  const appInsights = new ApplicationInsights({
    config: {
      enableAutoRouteTracking: false,
      disableFetchTracking: false,
      extensions: [appInsightPlugin as any],
      ...appInsightConfig
    }
  });
  appInsights.loadAppInsights();
  return appInsightPlugin;
};
