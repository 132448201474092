import { BookingStore } from "core/store/BookingStore";
import { NotificationStore } from "core/store/NotificationStore";
import { UserPreferencesStore } from "core/store/UserPreferencesStore";
import { createContext, PropsWithChildren, useContext } from "react";

const notificationStore = new NotificationStore();
const userPreferencesStore = new UserPreferencesStore();
const bookingStore = new BookingStore();

export const RootStore = {
  notificationStore,
  userPreferencesStore,
  bookingStore
};

const StoreContext = createContext(RootStore);

export const StoreContextProvider = ({
  children
}: PropsWithChildren<unknown>) => {
  return (
    <StoreContext.Provider value={RootStore}>{children}</StoreContext.Provider>
  );
};

export const useRootStore = () => useContext(StoreContext);
export const useBookingStore = () => useRootStore().bookingStore;
export const useNotificationStore = () => useRootStore().notificationStore;
export const useUserPreferencesStore = () =>
  useRootStore().userPreferencesStore;
