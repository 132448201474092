import { Route, RouteKey } from "./routes.types";

/**
 * This object describes BHB routes
 */
export const routes: Record<RouteKey, Route> = {
  profile: {
    path: "profile/*",
    fullPath: "/profile",
    title: "My profile | Best Health",
    subRoutes: {
      profileDetails: {
        path: "details",
        fullPath: "profile/details",
        title: "My profile | Details | Best Health",
        subRoutes: {
          profileDetailEdit: {
            path: "edit",
            fullPath: "details/edit",
            title: "My profile | Edit Details | Best Health",
            subRoutes: {}
          }
        }
      },
      profileSecurity: {
        path: "security",
        fullPath: "profile/security",
        title: "My profile | Security | Best Health",
        subRoutes: {}
      },
      profileManagement: {
        path: "management",
        fullPath: "profile/management",
        title: "My profile | Account Management | Best Health",
        subRoutes: {}
      },
      profileIncomplete: {
        path: "incomplete",
        fullPath: "profile/incomplete",
        title: "My profile | Complete | Best Health",
        subRoutes: {}
      }
    }
  },
  appointments: {
    path: "/appointments/*",
    fullPath: "/appointments",
    title: "My appointments | Best Health",
    subRoutes: {}
  },
  family: {
    path: "/family/*",
    fullPath: "/family",
    title: "Family members | Best Health",
    subRoutes: {
      addDependant: {
        path: "add",
        fullPath: "family/add",
        title: "Family members | Add new | Best Health",
        subRoutes: {}
      },
      editDependant: {
        path: "edit",
        fullPath: "family/edit",
        title: "Family members | Edit | Best Health",
        subRoutes: {}
      }
    }
  },
  dashboard: {
    path: "/dashboard/*",
    fullPath: "/dashboard",
    title: "Dashboard | Best Health",
    subRoutes: {
      dashboardProvider: {
        path: "/provider/*",
        fullPath: "dashboard/provider",
        title: "Provider | Best Health",
        subRoutes: {}
      },
      dashboardPractice: {
        path: "/practice/*",
        fullPath: "dashboard/practice",
        title: "Practice | Best Health",
        subRoutes: {}
      }
    }
  },
  bha: {
    path: "/bha/*",
    fullPath: "/bha",
    title: "BHA | Best Health",
    subRoutes: {
      profile: {
        path: "profile/*",
        fullPath: "bha/profile",
        title: "BHA | Profile | Best Health",
        subRoutes: {
          view: {
            path: "details",
            fullPath: "bha/profile/details",
            title: "BHA | Profile | Details | Best Health",
            subRoutes: {}
          },
          edit: {
            path: "details/edit",
            fullPath: "bha/profile/details/edit",
            title: "BHA | Profile | Edit details | Best Health",
            subRoutes: {}
          }
        }
      },
      family: {
        path: "family/*",
        fullPath: "bha/family",
        title: "BHA | Family | Best Health",
        subRoutes: {}
      }
    }
  },
  setup: {
    path: "setup/*",
    fullPath: "/setup",
    title: "Setup | Best Health",
    subRoutes: {
      confirm: {
        path: "confirm/*",
        fullPath: "setup/confirm",
        title: "Setup | Confirm | Best Health",
        subRoutes: {}
      },
      commit: {
        path: "commit/*",
        fullPath: "setup/commit",
        title: "Setup | Commit | Best Health",
        subRoutes: {}
      },
      cancel: {
        path: "cancel/*",
        fullPath: "setup/cancel",
        title: "Setup | Cancel | Best Health",
        subRoutes: {}
      },
      complete: {
        path: "complete/*",
        fullPath: "setup/complete",
        title: "Setup | Complete | Best Health",
        subRoutes: {}
      }
    }
  },
  booking: {
    path: "booking/*",
    fullPath: "/booking",
    title: "Booking | Best Health",
    subRoutes: {
      error: {
        path: "error",
        fullPath: "booking/error",
        title: "Booking | Error | Best Health",
        subRoutes: {}
      },
      patientType: {
        path: "patient-type",
        fullPath: "booking/patient-type",
        title: "Booking | Patient type | Best Health",
        subRoutes: {}
      },
      appointmentType: {
        path: "appointment-type",
        fullPath: "booking/appointment-type",
        title: "Booking | Appointment Type | Best Health",
        subRoutes: {}
      },
      appointmentTypeInformation: {
        path: "appointment-type-information",
        fullPath: "booking/appointment-type-information",
        title: "Booking | Appointment Type Information | Best Health",
        subRoutes: {}
      },
      appointmentSlot: {
        path: "appointment-slot",
        fullPath: "booking/appointment-slot",
        title: "Booking | Appointment Time | Best Health",
        subRoutes: {}
      },
      patient: {
        path: "patient",
        fullPath: "booking/patient",
        title: "Booking | Patient | Best Health",
        subRoutes: {}
      },
      addDependant: {
        path: "add-dependant",
        fullPath: "booking/add-dependant",
        title: "Booking | Add Dependant | Best Health",
        subRoutes: {}
      },
      notes: {
        path: "add-patient-notes",
        fullPath: "booking/add-patient-notes",
        title: "Booking | Add Patient Notes | Best Health",
        subRoutes: {}
      },
      confirm: {
        path: "confirm",
        fullPath: "booking/confirm",
        title: "Booking | Confirm | Best Health",
        subRoutes: {}
      },
      complete: {
        path: "complete",
        fullPath: "booking/complete",
        title: "Booking | Confirmed | Best Health",
        subRoutes: {}
      },
      unavailable: {
        path: "unavailable",
        fullPath: "booking/unavailable",
        title: "Booking | Unavailable | Best Health",
        subRoutes: {}
      },
      unaccepted: {
        path: "unaccepted",
        fullPath: "booking/unaccepted",
        title: "Booking | Unaccepted | Best Health",
        subRoutes: {}
      },
      practiceOutage: {
        path: "practice-outage",
        fullPath: "booking/practice-outage",
        title: "Booking | Practice Outage | Best Health",
        subRoutes: {}
      },
      completeProfile: {
        path: "complete-profile",
        fullPath: "booking/complete-profile",
        title: "Booking | Complete profile | Best Health",
        subRoutes: {}
      },
      policy: {
        path: "policy/*",
        fullPath: "booking/policy",
        title: "Booking | Policy| Best Health",
        subRoutes: {}
      },
      cancel: {
        path: "cancellation/*",
        fullPath: "booking/cancellation",
        title: "Booking | Cancellation| Best Health",
        subRoutes: {}
      },
      reschedule: {
        path: "reschedule/*",
        fullPath: "booking/reschedule",
        title: "Booking | Reschedule| Best Health",
        subRoutes: {}
      }
    }
  }
};
