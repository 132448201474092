import { useEffect } from "react";
import { useLocation } from "react-router-dom";

import { useAppInsightsContext } from "@microsoft/applicationinsights-react-js";

import { config } from "../../../config";
import { Environment } from "../../domain";

export const useAppInsightsPageViewTracker = () => {
  const appInsightPlugin = useAppInsightsContext();
  const location = useLocation();
  useEffect(() => {
    if (config.environment !== Environment.Dev) {
      appInsightPlugin.trackPageView &&
        appInsightPlugin.trackPageView({ name: document.title });
    }
  }, [location, appInsightPlugin.trackPageView, appInsightPlugin]);
};
