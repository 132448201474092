/**
 * This hook dynamically set main-height css custom property.
 * --main-height is used to calculate correct viewport for mobile-devices.
 */
import { useEffect } from "react";

import { useIsMobileView } from "./useIsMobileView";

// we multiply the viewport height by 1% to get a value for a vh unit
const VHRATIO = 0.01;

export const useWindowHeightResize = () => {
  const isMobile = useIsMobileView();
  const documentHeight = () => {
    if (!isMobile) return;

    const doc = document.documentElement;

    // set custom-property to use in index.css
    doc.style.setProperty("--main-height", `${window.innerHeight * VHRATIO}px`);
  };

  useEffect(() => {
    documentHeight();
    return () => {
      window.removeEventListener("resize", documentHeight);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMobile]);
};
