/**
 * @oieduardorabelo/use-user-agent has a critical vulnerability and the package hasn't been updated in 2 years even though there is PullRequest open to be merged.
 * I read the article below from medium and it seems like it would give us the information we are after.
 * @returns https://medium.com/@josephat94/building-a-custom-hook-to-detect-user-device-in-react-js-e4dd6e0d2d9c
 * We just need to determine if it is being accessed from tablets/ipads or mobile phones which comes with tablet or mobile
 */
export const useIsMobileView = () => {
  const userAgent = navigator.userAgent.toLowerCase();
  const isMobile = /iphone|ipad|ipod|android|blackberry|windows phone/g.test(
    userAgent
  );

  const isTablet = /(ipad|tablet|playbook|silk)|(android(?!.*mobile))/g.test(
    userAgent
  );

  return isMobile || isTablet;
};
