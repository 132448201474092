import { Literal } from "components/Literal";
import { PropsWithChildren } from "react";

import {
  Box,
  CircularProgress,
  LinearProgress,
  Modal,
  useTheme
} from "@mui/material";
import { SxProps } from "@mui/system";

interface SpinnerSxProps {
  sx?: SxProps;
  circularSize?: number;
}

export interface SpinnerProps extends SpinnerSxProps {
  variant?: "normal" | "inline" | "fullscreen" | "modal";
}

const FullScreenSpinner = ({
  sx,
  children
}: PropsWithChildren<SpinnerSxProps>) => {
  return (
    <>
      <LinearProgress />
      <Literal
        variant="title2"
        sx={{
          display: "flex",
          justifyContent: "center",
          py: 2,
          ...(sx || {})
        }}
      >
        {children}
      </Literal>
    </>
  );
};

const InlineSpinner = ({ sx, children }: PropsWithChildren<SpinnerSxProps>) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        ...(sx || {})
      }}
    >
      <LinearProgress sx={{ flex: "1 1 1px", marginRight: 1 }} />
      <Literal>{children}</Literal>
    </Box>
  );
};

const BoxSpinner = ({
  sx,
  children,
  circularSize
}: PropsWithChildren<SpinnerSxProps>) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
        py: 1,
        ...(sx || {})
      }}
    >
      <CircularProgress sx={{ marginRight: 2 }} size={circularSize} />
      <Literal>{children}</Literal>
    </Box>
  );
};

const ModalSpinner = ({ sx, ...rest }: PropsWithChildren<SpinnerSxProps>) => {
  const theme = useTheme();

  return (
    <Modal open={true}>
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        overflow="hidden"
        position="absolute"
        width="100vw"
        height="100vh"
      >
        <BoxSpinner
          sx={{
            backgroundColor: theme.palette.background.default,
            p: 2,
            borderRadius: theme.shape.borderRadius,
            border: `2px solid ${theme.palette.divider}`,
            height: "75px"
          }}
          {...rest}
        />
      </Box>
    </Modal>
  );
};

export const Spinner = ({
  variant,
  ...spinnerProps
}: PropsWithChildren<SpinnerProps>) => {
  switch (variant) {
    case "fullscreen":
      return <FullScreenSpinner {...spinnerProps} />;
    case "modal":
      return <ModalSpinner {...spinnerProps} />;
    case "inline":
      return <InlineSpinner {...spinnerProps} />;

    case "normal":
    default:
      return <BoxSpinner sx={{ height: "100%" }} {...spinnerProps} />;
  }
};
