import { BHPalette, FontWeight } from "components/theme";
import { ElementType, PropsWithChildren } from "react";

import { Typography, TypographyProps, useTheme } from "@mui/material";
import { Variant } from "@mui/material/styles/createTypography";

type FontColor = "p" | "s" | "d";
type TextVariant =
  | "headline"
  | "title1"
  | "title2"
  | "body1"
  | "body2"
  | "body3"
  | "body4"
  | "button";

export interface LiteralProps
  extends Omit<TypographyProps, "color" | "variant"> {
  /**
   * The theme font color to use - [P]rimary, [S]econdary, or [D]isabled
   * @default: 'p'
   */
  color?: FontColor | string;

  /**
   * The variant to use to dictate the default component, font size and weight.
   *
   * @remarks By default, the variants map to the following component | font size | font weight
   * ```
   * headline:  h1   | 24px | regular
   * title1:    h2   | 20px | medium
   * title2:    h3   | 20px | regular
   * body1:     span | 14px | regular
   * body2:     span | 13px | medium
   * body3:     span | 16px | regular
   * body4:     span | 14px | medium
   * button:    span | 20px | medium  | uppercase
   * ```
   * @default: 'body1'
   */
  variant?: TextVariant;

  /**
   * Optionally override the font weight.
   */
  fontWeight?: FontWeight;

  /**
   * The component to render.
   * @default: 'span'
   */
  component?: ElementType;
}

const mapColor = (color?: FontColor | string): string => {
  if (!color) return BHPalette.text.dark.primary;

  switch (color) {
    case "s":
      return BHPalette.text.dark.secondary;
    case "d":
      return BHPalette.text.dark.disabled;
    case "p":
      return BHPalette.text.dark.primary;
    default:
      return color;
  }
};

const mapVariant = (variant?: TextVariant): Variant => {
  switch (variant) {
    case "headline":
      return "h1";
    case "title1":
      return "h2";
    case "title2":
      return "h3";
    case "button":
      return "button";
    case "body1":
    case "body2":
    case "body3":
    case "body4":
    default:
      return "body1";
  }
};

const mapComponent = (
  component?: ElementType,
  variant?: TextVariant
): ElementType | undefined => {
  if (component) return component;

  switch (variant) {
    case "body1":
    case "body2":
    case "body3":
    case "body4":
      return "span";

    default:
      return undefined;
  }
};

export const Literal = ({
  color,
  fontWeight,
  variant,
  component,
  sx,
  children,
  ...typographyProps
}: PropsWithChildren<LiteralProps>) => {
  const theme = useTheme();
  const mappedVariant = mapVariant(variant);
  const mappedColor = mapColor(color);
  const mappedComponent = mapComponent(component, variant);

  // We need to create  literal props as an object literal to spread, otherwise TS complains if `component` is undefined
  const { fontSize, fontWeight: defaultFontWeight } =
    theme.typography[variant ?? "body1"];

  const props = {
    ...typographyProps,
    component: mappedComponent,
    variant: mappedVariant,
    color: mappedColor,
    sx: {
      fontSize,
      fontWeight: fontWeight ?? defaultFontWeight,
      ...(sx || {})
    }
  };

  return <Typography {...props}>{children}</Typography>;
};
